module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"3D Hearter","short_name":"3D Hearter","start_url":"/","background_color":"#353238","theme_color":"#353238","display":"standalone","icon":"src/assets/img/website-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"lp6LNR8FVptAfJrXdDpmmwkXbkwDWRtb","devKey":"XM2sqh9gJI6pDHMGzmNH2A2eknNnAihg","trackPage":true,"host":"https://adhd.3dhearter.com","delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
