// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ascending-aortic-aneurysm-js": () => import("./../src/pages/ascending-aortic-aneurysm.js" /* webpackChunkName: "component---src-pages-ascending-aortic-aneurysm-js" */),
  "component---src-pages-atrial-septal-defect-js": () => import("./../src/pages/atrial-septal-defect.js" /* webpackChunkName: "component---src-pages-atrial-septal-defect-js" */),
  "component---src-pages-coronary-artery-bypass-grafting-js": () => import("./../src/pages/coronary-artery-bypass-grafting.js" /* webpackChunkName: "component---src-pages-coronary-artery-bypass-grafting-js" */),
  "component---src-pages-double-aortic-arch-js": () => import("./../src/pages/double-aortic-arch.js" /* webpackChunkName: "component---src-pages-double-aortic-arch-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-of-3-d-heart-models-js": () => import("./../src/pages/Library-of-3D-heart-models.js" /* webpackChunkName: "component---src-pages-library-of-3-d-heart-models-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-tetralogy-of-fallot-js": () => import("./../src/pages/tetralogy-of-fallot.js" /* webpackChunkName: "component---src-pages-tetralogy-of-fallot-js" */),
  "component---src-pages-truncus-arteriosus-js": () => import("./../src/pages/truncus-arteriosus.js" /* webpackChunkName: "component---src-pages-truncus-arteriosus-js" */)
}

